.basic-info-item {
  margin: 0 auto;
  font-size: 14px;
  max-width: 216px;
  padding: 30px 20px;
  border-radius: 3px;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
}
.basic-info-item .icon {
  width: 80px;
  height: 80px;
  color: #ffffff;
  font-size: 30px;
  line-height: 80px;
  border-radius: 3px;
  text-align: center;
  position: relative;
  margin: 0 auto 30px;
  background-color: #ffc107;
}

.icon > small {
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 3px;
  position: absolute;
}
.icon > small:before {
  top: -16px;
  left: -16px;
  content: "";
  width: 30px;
  height: 30px;
  position: absolute;
  background-color: #151515;
  transform: rotate(45deg);
}
