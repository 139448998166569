.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide .textArea{
  visibility: hidden !important;
	opacity: 0 !important;
  transition: opacity 1s;
}

.swiper-slide .textArea{
	visibility: visible !important;
	opacity: 1 !important;
}

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.swiper-button-prev {
  background: #fff none repeat scroll 0 0;
  border-radius: 0;
  color: #333 !important;
  font-size: 30px;
  height: 80px !important;
  line-height: 70px;
  padding: 5px 5px;
  position: absolute;
  text-align: center;
  top: 45%;
  width: 45px !important;
  left: 0 !important;
  right: auto;
  opacity: 0 !important;
  transition: opacity 0.8s;
}

.swiper-button-next {
  background: #fff none repeat scroll 0 0;
  border-radius: 0;
  color: #333 !important;
  font-size: 30px;
  height: 80px !important;
  line-height: 70px;
  padding: 5px 5px;
  position: absolute;
  text-align: center;
  top: 45%;
  width: 45px !important;
  right: 0 !important;
  left: auto;
  opacity: 0 !important;
  transition: opacity 0.8s;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: #fbb908 none repeat scroll 0 0;
  color: #fff;
}

.mySwiper:hover .swiper-button-next, 
.mySwiper:hover .swiper-button-prev{
	visibility: visible !important;
	opacity: 1 !important;
}
