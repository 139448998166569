.construct-responsive-menu {
  display: none;
  position: absolute;
  right: 0;
  top: 0px;
  width: 100%;
  z-index: 999;
}

.slicknav_nav a {
  font-size: 16px;
  margin: 0;
  text-transform: capitalize;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  color: #000;
}

.slicknav_menu {
  background: transparent;
}

.slicknav_menutxt {
  display: none !important;
}

.slicknav_arrow {
  float: right;
  color: #000;
}

.slicknav_nav a:hover {
  border-radius: 0;
}

.slicknav_nav .slicknav_row:hover {
  border-radius: 0;
}

.slicknav_nav a:hover,
.slicknav_nav .slicknav_row:hover {
  background: #fbb908 none repeat scroll 0 0;
  color: #fff;
}

.slicknav_nav li {
  border-bottom: 1px solid #aaa;
}

.slicknav_nav li:last-child {
  border-bottom: 0px solid #aaa;
}

.slicknav_nav {
  background: #fff none repeat scroll 0 0;
  border-top: 1px solid #e5aa0a;
  box-shadow: 0 0 8px rgba(34, 30, 31, 0.3);
}

.slicknav_btn {
  background-color: transparent !important;
  float: left;
  margin: 0;
  line-height: 0;
}

.slicknav_menu .slicknav_icon {
  float: none !important;
  margin: 0;
}

.slicknav_icon-bar {
  height: 3px !important;
  margin: 5px 0;
  width: 30px !important;
}

.slicknav_menu .slicknav_icon-bar {
  background-color: #fff;
}

.navbar {
  font-weight: 1000 !important;
}

.nav-link {
  color: #fff !important;
}

.active{
    color: #e5aa0a !important;
}