.dataTables-info{
    padding-top: 0.85em;
    white-space: normal;
    color: #4361ee;
    font-weight: 600;
    border: 1px solid #e0e6ed;
    display: inline-block;
    padding: 10px 16px;
    border-radius: 6px;
    font-size: 13px;
}
.page-count-item{
    display: flex;
}
.disabled-button svg{
    color: #fff;
    background-color: inherit;
    cursor:default;
}
.prishtha {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    font-size: 14px;
    color: #3b3f5c;
}
.prishtha svg {
    width: 18px;
    height: 18px;
    vertical-align: text-bottom;
}
/* .prishtha .pagination { margin-bottom: 0 } */
.prishtha li a {
    padding: 5px 9px;
    font-weight: 600;
    color: #3b3f5c;
}
.prishtha li {
    padding: 4px 0;
    font-weight: 600;
    color: #3b3f5c;
    background-color: rgba(0, 23, 55, 0.08);
    margin: 0 3px;
    border-radius: 50%;
}
.prishtha li :active {
    /* background-color: #4361ee; */
    /* border-radius: 50%; */
    color: #fff;
  }
  .active-page{
    color: #fff !important;
    background-color: #4361ee !important;
}