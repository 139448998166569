:root {
  --active-color: black;
  /* --active-color: rgb(14, 214, 147); */
  --native-color: #e0e0e0;
}

.customContainer {
  text-align: left;
  font-weight: bold;
}

.progress-container {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 40px; */
  max-width: 100%;
  width: 330px;
  position: relative;
}

.circleTitles-container {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 40px; */
  max-width: 100%;
  width: 400px;
  margin-left: -20px;
  position: relative;
}

.progress-container::before {
  content: " ";
  background-color: var(--native-color);
  position: absolute;
  left: 0;
  width: calc(100% - 1%);
  height: 3px;
  top: 15px;
  transform: translateY(-50%);
  z-index: -1;
}

.progressBar {
  background-color: var(--active-color);
  position: absolute;
  left: 0;
  width: 0%;
  height: 3px;
  top: 15px;
  transform: translateY(-50%);
  z-index: -1;
}

.customContainer .circle {
  background-color: #ffffff;
  color: var(--native-color);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 3px solid var(--native-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.customContainer .circle.active {
  border: 3px solid var(--active-color);
  color: var(--active-color);
}

.customContainer .btn {
  background-color: var(--active-color);
  border: transparent;
  margin: 5px;
  padding: 8px 25px;
  border-radius: 15px;
  color: #ffffff;
  cursor: pointer;
  font-family: inherit;
}

.customContainer .btn:active {
  transform: scale(0.98);
}

.customContainer .btn:disabled {
  background-color: var(--native-color);
  cursor: not-allowed;
}

.getAdmissionBtn {
  background-color: #fbb908 !important;
  padding: 5px 30px !important;
  font-weight: 700 !important;
}

.paymentIconContainer {
  width: "60%";
}

@media only screen and (max-width: 350px) {
  .paymentIconContainer {
    width: "100%";
  }
}
