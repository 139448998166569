
/* .prishtha {
    text-align: center;
    justify-content: center;
    justify-items: center;
    color: 'rgba(0, 23, 55, 0.08)';
    font-size: 14px;
}
.prishtha li {
    margin: 3px;
    padding: 5px 8px;
    background-color: rgba(0, 23, 55, 0.08);
    border-radius: 5px;
  }
.prishtha li a {
    padding: 10px 15px;
    font-weight: 600;
    color: #3b3f5c;
  }
  
  .prishtha li svg {
    list-style: none;
    text-decoration: none;
    color: red;
    background-color: gray ;
    padding: 5px;
    border-radius: 50%;
  } */
  
  .table-row-bg-color{
    background-color: #c0c0c073;
  }