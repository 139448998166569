.whatsappBtn {
  width: 140px;
  position: fixed;
  bottom: 30px;
  right: 80px;
  z-index: 2;
}
.whatsappText {
  display: block !important;
  color: #fff;
}


@media only screen and (max-width: 1000px) {
  .whatsappBtn {
    width: 50px;
    right: 10px;
  }
  .whatsappText {
    display: none !important;
  }
}
